import React from 'react';
import PropTypes from 'prop-types';
import { TabContainer } from '../TabContainer/TabContainer.js';
import { GatsbyImage } from 'gatsby-plugin-image';
import Modal from 'react-modal';
import './CollectionItem.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './CollectionMenu.scss';

Modal.setAppElement('#___gatsby');

const CollectionItem = ({ item, tabs }) => {
    const { isMobileOrTablet, isMobile } = useBreakpoint();

    return (
        <>
            <div className="section blv-collection-item blv-detail-item">
                <div className="container">
                    <div className={`columns is-gapless is-multiline ${isMobileOrTablet ? 'is-centered' : ''}`}>
                        <div className="column is-8-tablet is-5-desktop has-text-centered">
                            <div className="columns is-mobile is-centered is-gapless is-multiline">
                                {isMobileOrTablet && (
                                    <div className="column is-12-mobile is-12-tablet">
                                        <h1 className="blv-detail-title with-border mobile">
                                            {item.name.toLowerCase()}
                                        </h1>
                                    </div>
                                )}
                                <div className="column is-6-mobile is-6-tablet">
                                    {item && item.image && (
                                        <GatsbyImage
                                            image={item.image.childImageSharp.gatsbyImageData}
                                            alt={item.alt}
                                            title={`Photo of ${item.name}`}
                                            className="blv-product-bottle-img"
                                            objectFit="contain"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="column is-12-tablet is-7-desktop is-6-widescreen is-5-fullhd"
                            style={{ paddingBottom: isMobile && `20px` }}
                        >
                            {!isMobileOrTablet && (
                                <h1 className="blv-detail-title with-border">{item.name.toLowerCase()}</h1>
                            )}
                            {tabs && <TabContainer className="blv-tabs-collection" tabs={tabs} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

CollectionItem.propTypes = {
    item: PropTypes.object.isRequired,
    tabs: PropTypes.array,
};

export default CollectionItem;
