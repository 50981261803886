import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import CollectionMenu from '../components/Collection/CollectionMenu';
import * as collectionData from '../utils/getCollectionData';
import { getComponent } from '../utils/getComponent';
import { getSafe } from '../utils/getSafe';
// import { BartenderContextProvider } from '../components/Bartender/context/BartenderContext';
import { collectionBottlesMenu } from '../utils/collectionBottlesMenu';
import '../styles/detail-item.scss';

export default function Collection({ data, pageContext }) {
    React.useEffect(() => {
        document.body.classList.add('colored-nav');

        return () => document.body.classList.remove('colored-nav');
    }, []);

    const langKey = pageContext.langcode;
    const newsLetterConfig = getSafe(() => data.nodeNewsletterForm, []);

    let allNewsletterFormSignUpCopy = getSafe(() => data.allNewsletterFormSignUpCopy, []);
    if(allNewsletterFormSignUpCopy.edges) {
        allNewsletterFormSignUpCopy = allNewsletterFormSignUpCopy.edges.map(function(elem) {
            return {
                copy:  getSafe(() => elem.node.field_signing_up_copy_2.value, ''),
                copy_2: getSafe(() => elem.node.field_signing_up_copy_block_2.value, ''),
                lang: elem.node.relationships.field_language.field_language_code,
                display_sign_up_checkbox: getSafe(() => elem.node.field_display_sign_up_checkbox, false),
            } 
        });
    }

    const title = getSafe(() => data.nodeProducts.field_seo_metatag.title, '');
    const description = getSafe(() => data.nodeProducts.field_seo_metatag.description, '');
    const keywords = getSafe(() => data.nodeProducts.field_seo_metatag.keywords, null);
    const metaImage = getSafe(
        () =>
            data.nodeProducts.relationships.field_product_seo_meta_image.relationships.field_media_image.localFile
                .publicURL,
        null
    );
    const regions = getSafe(() => data.regions.edges, []).reduce((regionsData, data) => {
        let countries = getSafe(() => data.node.relationships.field_region, []);
        return [...regionsData, ...countries];
    }, []);
    const tastingNotes = collectionData.getTastingNotes(data);
    const collectionItem = collectionData.getCollectionData(data);
    const vendors = collectionData.getVendors(data);
    const contentBlocks = getSafe(() => data.nodeProducts.relationships.field_paragraphs, []);
    const currentPage = contentBlocks.map((block, index) => getComponent(block, index, []));
    const tabs = collectionData.getTabs(data, tastingNotes, vendors);

    const fbDomainVerificationCode = getSafe(() => data.nodeSiteSettings.field_domain_verification_code, '');
    return (
        // <BartenderContextProvider>
        <Layout
            langKey={langKey}
            shopMenu={data.nodeShop}
            dataMenu={getSafe(() => data.allMenuLinkContentMenuLinkContent, [])}
            siteSettings={getSafe(() => data.nodeSiteSettings, [])}
            langPageRelation={getSafe(() => data.relatedNodeProducts.edges, [])}
            pageContext={pageContext}
            newsLetterConfig={newsLetterConfig}
            allNewsletterFormSignUpCopy={allNewsletterFormSignUpCopy}
            regions={regions}
            disableTheAgeGate={getSafe(() => data.nodeProducts.field_disable_the_age_gate, false)}
        >
            <SEO
                title={title}
                lang={langKey}
                description={description}
                keywords={keywords}
                image={metaImage}
                fbDomainVerificationCode={fbDomainVerificationCode}
                langPageRelation={getSafe(() => data.relatedNodeProducts.edges, [])}
                noIndex={getSafe(() => data.nodeProducts.field_add_no_index_rule, false)}
            />
            <CollectionMenu
                collectionItem={collectionItem}
                tabs={tabs}
                collectionBottlesMenu={collectionBottlesMenu(data.nodeCollections)}
            />
            {currentPage}
        </Layout>
        // </BartenderContextProvider>
    );
}

export const pageQuery = graphql`
    query($slug: String!, $langcode: String!, $pagecode: String!) {
        nodeSiteSettings(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...SiteSettingsItems
        }
        nodeNewsletterForm(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...NewsletterItems
        }
        allNewsletterFormSignUpCopy: allNodeNewsletterForm {
            edges {
                node {
                    field_signing_up_copy_2 {
                        value
                    }
                    field_signing_up_copy_block_2 {
                        value
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                    field_display_sign_up_checkbox
                }
            }
        }
        regions: allNodeRegionsConfigurations(filter: { title: { regex: "/Regions Configurations*/" } }) {
            edges {
                node {
                    relationships {
                        field_region {
                            value: field_code
                            label: field_name
                            relationships {
                                field_local {
                                    name
                                    field_language_code
                                }
                            }
                            field_legal_for_en_int {
                                value
                            }
                            field_legal_for_de_de {
                                value
                            }
                            field_legal_for_fr_fr {
                                value
                            }
                            field_legal_bottom_for_de_de {
                                value
                            }
                            field_legal_bottom_for_en_int {
                                value
                            }
                            field_legal_bottom_for_fr_fr {
                                value
                            }
                            field_display_sign_up_checkbox
                        }
                    }
                }
            }
        }
        nodeProducts(
            fields: { slug: { eq: $slug } }
            relationships: { field_language: { field_language_code: { eq: $langcode } } }
        ) {
            ...ProductsInfo
        }
        nodeCollections(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...CollectionInfo
        }
        nodeShop(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...ShopItems
        }
        allMenuLinkContentMenuLinkContent(
            filter: {
                relationships: { field_language_code: { field_language_code: { eq: $langcode } } }
                enabled: { eq: true }
            }
            sort: { fields: weight, order: ASC }
        ) {
            edges {
                node {
                    weight
                    name: title
                    drupal_id
                    link {
                        uri
                    }
                    drupal_parent_menu_item
                    langcode
                    relationships {
                        field_content {
                            ...LegalMenuItems
                            ...ParagraphMenuItems
                            ...ContactMenuItems
                            ...ProductsMenuItems
                            ...NutritionMenuItems
                        }
                        field_collection_related {
                            ...CollectionMenuItems
                        }
                    }
                    menu_name
                    enabled
                    bundle
                }
            }
        }
        relatedNodeProducts: allNodeProducts(
            filter: {
                field_page_code: { eq: $pagecode }
                relationships: { field_language: { field_language_code: { ne: $langcode } } }
            }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    path {
                        alias
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                }
            }
        }
    }
`;

Collection.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
};
