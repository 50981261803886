import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { getLinkCollectionMenu } from '../../utils/getCollectionData';
import { getSafe } from '../../utils/getSafe';
import Chevron from '../UI/Icons/Chevron';
import CollectionItem from './CollectionItem';
import './CollectionMenu.scss';
import Link from '../UI/Link/Link';

const CollectionMenu = ({ collectionBottlesMenu, collectionItem, tabs }) => {
    const breakpoints = useBreakpoint();
    const [dropdownIsOpen, toggleDropdown] = useState(false);

    const groupByCollection = bottles => {
        return bottles
            .sort((a, b) => a.node.collection.order - b.node.collection.order)
            .reduce((result, bottle) => {
                (result[bottle.node.collection.name] = result[bottle.node.collection.name] || []).push(bottle);
                return result;
            }, {});
    };

    return (
        <div className="blv-collection-menu has-text-black">
            {breakpoints && !breakpoints.isDesktop && (
                <>
                    <div className={`blv-collection-carousel ${dropdownIsOpen ? '' : 'is-closed'}`}>
                        <div className="blv-carousel-scroll-wrapper">
                            {collectionBottlesMenu &&
                                Object.entries(groupByCollection(collectionBottlesMenu)).map(([title, collection]) => {
                                    const productRelated = getSafe(
                                        () => collection[0].node.collection.pageRelated,
                                        null
                                    );
                                    const collectionRelated = getSafe(
                                        () => collection[0].node.collection.collectionRelated,
                                        null
                                    );
                                    const link = getLinkCollectionMenu(productRelated, collectionRelated);
                                    return (
                                        <div key={title} className="blv-title-image-wrapper is-flex">
                                            <div className="blv-collection-title is-uppercase has-text-white">
                                                {link ? (
                                                    <Link to={link}>
                                                        {title}
                                                        <Chevron title="Chevron" fill="#fff" />
                                                    </Link>
                                                ) : (
                                                    <>{title}</>
                                                )}
                                            </div>
                                            {collection.map(item => {
                                                return (
                                                    <Link
                                                        key={item.node.key}
                                                        label={item.node.key}
                                                        to={item.node.fields.slug}
                                                        onClick={() => toggleDropdown(!dropdownIsOpen)}
                                                        className="blv-collection-thumbnail has-text-black"
                                                    >
                                                        {item.node.thumbnail && (
                                                            <GatsbyImage
                                                                image={item.node.thumbnail.childImageSharp.gatsbyImageData}
                                                                alt={`Thumbnail photo of ${item.node.name} vodka`}
                                                                title={`Thumbnail photo of ${item.node.name} vodka`}
                                                            />
                                                        )}
                                                        {breakpoints && breakpoints.isDesktop && (
                                                            <div className="blv-bottle-name">{item.node.name}</div>
                                                        )}
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <button onClick={() => toggleDropdown(!dropdownIsOpen)} className="blv-carousel-dropdown">
                        {breakpoints && breakpoints.isDesktop && (
                            <div className="dropdown-title is-uppercase">
                                {dropdownIsOpen ? <>Close</> : <>View Collection</>}
                            </div>
                        )}
                        <Chevron className="dropdown-chevron" title="Chevron" fill="#fff" />
                    </button>
                </>
            )}
            <CollectionItem item={collectionItem} tabs={tabs} />
        </div>
    );
};

CollectionMenu.propTypes = {
    collectionItem: PropTypes.object.isRequired,
    collectionBottlesMenu: PropTypes.array.isRequired,
    tabs: PropTypes.array,
};

export default CollectionMenu;
